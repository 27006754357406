.container {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  height: 100%;
  // background-color: gray;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    height: 25rem;
  }

  span {
    font-size: 7rem;
    background: linear-gradient(to right,var(--brown-color) 30%, var(--green-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


@media (min-width: 1200px) {

  
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
  .container{
    span{
      font-size: 3rem;
    }
  }
}

@media (max-width: 600px) {
  .container{
    span{
      font-size: 3rem;
    }
  }
}